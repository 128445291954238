<template>
  <div class="animated fadeIn">
    <b-card>
      <b-card-header>
        Detail User <strong>( {{ this.name }} )</strong>
        <div class="card-header-actions">
          <a class="card-header-action" href="withdaw-sell" rel="noreferrer noopener">
            <small class="text-muted">Back</small>
          </a>
        </div>
      </b-card-header>
      <b-card-body>
        <div class="loading" v-if="isLoading == true">
          <div class="sk-three-bounce">
            <div class="sk-child sk-bounce1"></div>
            <div class="sk-child sk-bounce2"></div>
            <div class="sk-child sk-bounce3"></div>
          </div>
        </div>
        <b-row class="mb-4">
          <b-col sm="4">
            <h6>Email</h6>
            <div class="mb-3" v-if="this.email == null"><strong>-</strong></div>
            <div class="mb-3" v-else><strong>{{ this.email }}</strong></div>
            <h6>Phone</h6>
            <div class="mb-3" v-if="this.phone == null"><strong>-</strong></div>
            <div class="mb-3" v-else><strong>{{ this.phone }}</strong></div>
            <h6>Gold Balance</h6>
            <div class="mb-3" v-if="this.gold_balance == null"><strong>-</strong></div>
            <div class="mb-3" v-else><strong>{{ this.gold_balance + ' gram' }}</strong></div>
            <h6>Voucher Balance</h6>
            <div class="mb-3" v-if="this.bucket_balance_gram == null"><strong>-</strong></div>
            <div class="mb-3" v-else><strong>{{ this.bucket_balance_gram + ' gram' }}</strong></div>
            <h6>Voucher Balance IDR</h6>
            <div class="mb-3" v-if="this.bucket_balance == null"><strong>-</strong></div>
            <div class="mb-3" v-else><strong>{{ this.bucket_balance }}</strong></div>
            <h6>Nomor Invoice</h6>
            <div class="mb-3" v-if="this.invoice_number == null"><strong>-</strong></div>
            <div class="mb-3" v-else><strong>{{ this.invoice_number }}</strong></div>
          </b-col>
          <b-col sm="4">
            <h6>Description</h6>
            <div class="mb-3" v-if="this.description == null"><strong>-</strong></div>
            <div class="mb-3" v-else><strong>{{ this.description }}</strong></div>
            <h6>IDR Amount</h6>
            <div class="mb-3" v-if="this.amount == null"><strong>-</strong></div>
            <div class="mb-3" v-else>
              <!-- <strong v-if="this.bank_code === 'BNINIDJAXXX' || this.bank_code === 'SYNIIDJ1'">
                {{ Number(this.amount) | currency}}
              </strong>
              <strong v-else-if="this.listing == false && this.bank_code !== 'BNINIDJAXXX' || this.bank_code !== 'SYNIIDJ1'">
                {{ (Number(Number(this.amount) - 2500)) | currency}}
              </strong> -->
              <strong>
                {{ (Number(this.amount)) | currency }}
              </strong>
              </div>
            <h6>Gold Amount</h6>
            <div class="mb-3" v-if="this.gold_amount == null"><strong>-</strong></div>
            <div class="mb-3" v-else><strong>{{ this.gold_amount + ' Gram'}}</strong></div>
            <h6>Request Date</h6>
            <div class="mb-3" v-if="this.created_at == null"><strong>-</strong></div>
            <div class="mb-3" v-else><strong>{{ this.created_at }}</strong></div>
            <h6>Release Date</h6>
            <div class="mb-3" v-if="this.updated_at == null"><strong>-</strong></div>
            <div class="mb-3" v-else><strong>{{ this.status !== 1 ? '-' : this.updated_at }}</strong></div>
            <h6>Partner</h6>
            <div class="mb-3"><strong>{{ this.partner }}</strong></div>
          </b-col>
          <b-col sm="4">
            <h6>Account Receiver Name</h6>
            <div class="mb-3" v-if="this.bank.owner_name == null"><strong>-</strong></div>
            <div class="mb-3" v-else><strong>{{ this.bank.owner_name }}</strong></div>
            <h6>Tujuan Pencairan Dana</h6>
            <div class="mb-3" v-if="this.bank.bank_name == null"><strong>-</strong></div>
            <div class="mb-3" v-else><strong>{{ this.bank.bank_name }}</strong></div>
            <h6>Account Receiver Number</h6>
            <div class="mb-3" v-if="this.bank.account_number == null"><strong>-</strong></div>
            <div class="mb-3" v-else><strong>{{ this.bank.account_number }}</strong></div>
            <h6>Transaction Status</h6>
            <div class="mb-3" v-if="this.status_text !== null">
              <strong>
                {{ this.status_text }}
              </strong>
            </div>
            <div class="mb-3" v-else><strong>- </strong></div>
            <h6>Reason</h6>
            <div class="mb-3" v-if="this.reason == null"><strong>-</strong></div>
            <div class="mb-3" v-else><strong>{{ this.reason  }}</strong></div>
          </b-col>
        </b-row>
      </b-card-body>
      <b-card-header>
        History
      </b-card-header>
      <b-card-body>
        <div>
          <vuetable ref="vuetable"
            @vuetable:load-error="handleLoadError"
            :api-url="apiUrl"
            :http-options="HttpOptions"
            :fields="fields"
            pagination-path=""
            :muti-sort="true"
            :sort-order="sortOrder"
            :append-params="moreParams"
            @vuetable:pagination-data="onPaginationData">
              <template slot="name-slot" slot-scope="prop">
                <span>{{ prop.rowData.name }}</span>
              </template>
              <template slot="bank_name-slot" slot-scope="prop">
                <span>{{ prop.rowData.bank_name }} - {{ prop.rowData.account_number }}</span>
              </template>
              <template slot="amount-slot" slot-scope="prop">
                <!-- <span v-if="prop.rowData.bank_account.bank_id === 'BNINIDJAXXX' || prop.rowData.bank_account.bank_id === 'SYNIIDJ1'">
                  {{ Number(prop.rowData.amount) | currency}}
                </span>
                <span v-else-if="prop.rowData.listing == false && prop.rowData.bank_account.bank_id !== 'BNINIDJAXXX' || prop.rowData.bank_account.bank_id !== 'SYNIIDJ1'">
                  {{ (Number(Number(prop.rowData.amount) - 2500)) | currency}}
                </span> -->
                <span>
                  {{ Number(prop.rowData.amount) | currency}}
                </span>
              </template>
            </vuetable>
          <div class="vuetable-pagination ui basic segment grid">
            <vuetable-pagination-info ref="paginationInfo"
            ></vuetable-pagination-info>

            <vuetable-pagination ref="pagination"
              @vuetable-pagination:change-page="onChangePage"
            ></vuetable-pagination>
          </div>
        </div>
      </b-card-body>
      <b-card-header v-if="this.status != 1 && $can('reject') && this.status != 2 && this.listing === false || this.can_retry === true && $can('reject')">
        Reject Jual Emas
      </b-card-header >
      <b-card-body v-if="this.status != 1 && $can('reject') && this.status != 2 && this.listing === false || this.can_retry === true && $can('reject')">
        <b-row>
          <b-col sm>
            <h6>Reject Jual Emas</h6>
            <form v-on:submit.prevent="reject">
              <b-form-group id="reasonInputGroup1"
                label-for="reason"
              >
                <b-form-input id="reason"
                  type="text"
                  size="md"
                  v-model.lazy.trim="$v.form.reason.$model"
                  :state="chkState('reason')"
                  aria-describedby="reasonFeedback"
                  placeholder="Alasan jual emas"
                  autocomplete='given-name'
                  autofocus
                >
                </b-form-input>
                <b-form-invalid-feedback id="reasonFeedback" v-for="(error , index) in errors.form.reason" :key="index">
                  - {{ error }} <br>
                </b-form-invalid-feedback>
              </b-form-group>
            </form>
          </b-col>
          <b-col sm="2">
            <b-input-group-append style="margin-top:20px">
              <b-button size="md" type="submit" @click="reject" variant="success">Reject</b-button>
            </b-input-group-append>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required } from "vuelidate/lib/validators"
import accounting from 'accounting'
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'

export default {
  name: 'detail-wtihdraw',
  components: {
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
  },
  data() {
    return {
      form: {
        reason: "",
      },
      email: "",
      phone: "",
      hashed: '',
      address: "",
      status: "",
      can_retry: false,
      description: "",
      amount: "",
      gold_amount: "",
      status_text: null,
      gold_balance: "",
      created_at: "",
      updated_at: "",
      bank: "",
      bank_code: "",
      listing: false,
      reason: "",
      admin_fee: "",
      invoice_number: null,
      partner: '',
      bucket_balance: null,
      bucket_balance_gram: '',
      name: "",
      user: '',
      apiUrl: process.env.VUE_APP_SECRET + process.env.VUE_APP_CONFIG + `history-withdraw-sell/` + this.$route.params.id,
      HttpOptions: {
        headers: {
          'Accept' : 'application/json',
          'Authorization' : 'Bearer ' + localStorage.getItem('access_token')
        }
      },
      sortOrder: [
        {
          field: 'created_at',
          direction: 'desc'
        }
      ],
      moreParams: {},
      fields: [
        {
          name: 'created_at',
          sortField: 'created_at',
          title: 'Date'
        },
        {
          name: 'name-slot',
          sortField: 'name',
          title: 'Name'
        },
        {
          name: 'bank_name-slot',
          sortField: 'bank_name',
          title: 'Bank Account'
        },
        {
          name: 'amount-slot',
          sortField: 'amount',
          title: 'Nilai Rupiah'
        },
        {
          name: 'gold_amount',
          title: 'Jumlah Emas',
          formatter: this.goldField
        },
        {
          name: 'status',
          sortField: 'status',
          formatter: this.statusField
        }
      ],
      errors: {
        message: [],
        status: [],
        code: '',
        headers: [],
        form: {
          reason: [],
        },
      },
      isLoading: false
    }
  },
  computed: {
    formStr() { return JSON.stringify(this.form, null, 4) },
    isValid() { return !this.$v.form.$anyError },
    isDirty() { return this.$v.form.$anyDirty },
    invCheck() { return 'You must accept before submitting' },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      reason: {
        required
      }
    }
  },
  created () {
    this.$http.put(`detail-withdraw-sell/` + this.$route.params.id)
      .then((result) => {
          this.user = result.data.meta.data.sell.id;
          this.hashed = result.data.meta.data.user.hashed_id;
          this.name = result.data.meta.data.user.name;
          this.email = result.data.meta.data.user.email;
          this.phone = result.data.meta.data.user.phone;
          this.address = result.data.meta.data.user.address;
          this.partner = result.data.meta.data.user.user_from;
          this.description = result.data.meta.data.sell.description;
          this.amount = result.data.meta.data.sell.amount;
          this.admin_fee = result.data.meta.data.sell.admin_fee;
          this.gold_amount = result.data.meta.data.sell.gold_amount;
          this.gold_balance = result.data.meta.data.user.gold_balance;
          this.created_at = result.data.meta.data.sell.created_at;
          this.can_retry = result.data.meta.data.sell.can_retry;
          this.updated_at = result.data.meta.data.sell.updated_at;
          this.bank = result.data.meta.data.sell.bank;
          this.invoice_number = result.data.meta.data.sell.transaction_id;
          this.bank_code = this.bank.bank_id
          this.status = result.data.meta.data.sell.status;
          this.status_text = result.data.meta.data.sell.status_text
          this.reason = result.data.meta.data.sell.reason;
          this.listing = result.data.meta.data.sell.listing;
          this.bucket_balance = result.data.meta.data.bucket_balance;
          this.bucket_balance_gram = result.data.meta.data.bucket_balance_gram;
      }).catch((error) => {
        if (error.response) {
          this.errors.code = error.response.status;
          this.errors.message = error.response.data.meta.message;
          this.errors.status = error.response.data.meta.code;
        }
      })
  },
  methods: {
    statusField(value) {
      return value == 0 ? 'Sedang Diproses' : (value == 1 ? 'Berhasil' : (value == 2 ? 'Gagal' : '-'))
    },
    goldField(value) {
      return value + ' Gram'
    },
    handleLoadError(error) {
      this.errors.code = error.response.data.meta.code;
      this.errors.message = error.response.data.meta.message;
      this.errors.status = error.response.data.meta.code;
      if (this.errors.code == 401) {
        if (localStorage.getItem('access_token') != null) {
          localStorage.removeItem('access_token');
          this.$swal.fire(
            'Your session expired!',
            'Your session has expired. Please login again to access this page!',
            'error'
          ).then(() =>{
            this.$router.push("/login")
          })
        }
      }else if(this.errors.code == 403) {
        this.$router.push("/403")
      }else if(this.errors.code == 500) {
        this.$router.push("/500")
      }
    },
    formatNumber (value) {
      return 'Rp.'+accounting.formatNumber(value, 2)
    },
    number(value){
      return value
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    onPaginationData (paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
      this.$refs.paginationInfo.setPaginationData(paginationData)
    },
    reject() {
      this.validate()
      this.isLoading = true
      this.$http.post(`withdraw-set-failed`, {
        user: this.user,
        reason: this.form.reason
      }).then(() => {
        this.isLoading = false
        this.$swal.fire(
          'Success!',
          'Jual emas berhasil ditolak!',
          'success'
        ).then(() => {
          location.reload()
        })
      }).catch((error) => {
        if (error.response) {
          this.isLoading = false
          this.errors.code = error.response.status;
          this.errors.status = error.response.data.meta.code;
          this.errors.headers = error.response.headers;
          if(this.errors.status == 422) {
              this.errors.message = error.response.data.meta.message;
              this.errors.form.reason = this.errors.message.reason;
          }else if (this.errors.status  == 400) {
            this.errors.message = error.response.data.meta.message;
            this.$swal.fire(
              'Failed',
              this.errors.message,
              'error'
            ).then(() => {
              location.reload()
            })
          }else if (this.errors.status  == 404) {
            this.errors.message = error.response.data.meta.message;
            this.$swal.fire(
              'Failed',
              this.errors.message,
              'error'
            ).then(() => {
              location.reload()
            })
          }
        }
      })
    },
    chkState(val) {
      const field = this.$v.form[val]
      return !field.$dirty || !field.$invalid
    },
    findFirstError(component = this) {
      if (component.state === false) {
        if (component.$refs.input) {
          component.$refs.input.focus()
          return true
        }
        if (component.$refs.check) {
          component.$refs.check.focus()
          return true
        }
      }
      let focused = false
      component.$children.some((child) => {
        focused = this.findFirstError(child)
        return focused
      })

      return focused
    },
    validate() {
      this.$v.$touch()
      this.$nextTick(() => this.findFirstError())
      return this.isValid
    }
  },
}
</script>

<style>
    .submt {
        width: 100%;
        height: auto;
        display: block;
    }
</style>
<style>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
